.scheduleGrid {
    --grid-template-areas: '';
    --grid-template-columns: 0;

    display: grid;
    grid-template-areas: var(--grid-template-areas);
    grid-template-columns: repeat(var(--grid-template-columns), minmax(100%, 1fr));
    gap: 1rem;

    @media screen(lg) {
        grid-template-columns: repeat(var(--grid-template-columns), minmax(50%, 1fr));
    }

    @media screen(xl) {
        grid-template-columns: repeat(var(--grid-template-columns), minmax(33.3333333%, 1fr));
    }

    @media screen('2xl') {
        gap: 2rem;
        grid-template-columns: repeat(var(--grid-template-columns), minmax(calc(33.3333333% - 1rem), 1fr));
    }
}
